* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.container {
  overflow: auto;
  scroll-snap-type: x mandatory;
  display: flex;
}

.container::-webkit-scrollbar {
  display: none;
}

.area {
  scroll-snap-align: center;
  scroll-snap-stop: always;
  width: 100%;
  flex: none;
}

.scrollable {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.scrollableX {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.scrollableY {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.css-epd502 {
  margin: 0 !important;
  width: 100% !important;
  max-height: 520px !important;
}

.css-1dozdou {
  margin-top: 0 !important;
}

.css-1n2mv2k > span {
  width: 48px !important;
  height: 20px !important;
  font-size: 0.7rem !important;
  font-weight: bold !important;
  color: #8fc320 !important;
}

.MuiCalendarPicker-root {
  margin: 0 !important;
  width: 100% !important;
  max-height: 520px !important;
}

.PrivatePickersSlideTransition-root {
  height: 420px !important;
}
